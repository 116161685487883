import { Component, Input } from '@angular/core';

@Component({
    selector: 'ins-pdf-link',
    templateUrl: './pdf-link.component.html',
})
export class PdfLinkComponent {
    @Input() public pdfLink: string;
    @Input() public pdfLinkClass: string;
    @Input() public pdfLinkTitle: string;
}
