import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PdfLinkComponent } from '@app/shared/pdf-link/pdf-link.component';

/** @deprecated - this is moved to libs */
@NgModule({
    declarations: [PdfLinkComponent],
    exports: [PdfLinkComponent],
    imports: [CommonModule],
})
export class PdfLinkModule { }
